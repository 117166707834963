// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
  display: flex;
  gap: 10px 5px;
  flex-wrap: wrap;
}
.content .item {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 0.938rem;
  font-weight: 500;
  width: calc(50% - 2.5px);
}
.content .item .name {
  opacity: 0.6;
}
.content .item .value.interactive {
  text-transform: lowercase;
  color: var(--color-primary-shade);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNhci1kYXRhLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsYUFBQTtFQUNBLGFBQUE7RUFDQSxlQUFBO0FBQ0Y7QUFDRTtFQUNFLGFBQUE7RUFDQSxzQkFBQTtFQUNBLFFBQUE7RUFDQSxtQkFBQTtFQUNBLGdCQUFBO0VBQ0Esd0JBQUE7QUFDSjtBQUNJO0VBQ0UsWUFBQTtBQUNOO0FBR007RUFDRSx5QkFBQTtFQUNBLGlDQUFBO0FBRFIiLCJmaWxlIjoiY2FyLWRhdGEuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY29udGVudCB7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGdhcDogMTBweCA1cHg7XG4gIGZsZXgtd3JhcDogd3JhcDtcblxuICAuaXRlbSB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICAgIGdhcDogNXB4O1xuICAgIGZvbnQtc2l6ZTogMC45MzhyZW07XG4gICAgZm9udC13ZWlnaHQ6IDUwMDtcbiAgICB3aWR0aDogY2FsYyg1MCUgLSAyLjVweCk7XG5cbiAgICAubmFtZSB7XG4gICAgICBvcGFjaXR5OiAwLjY7XG4gICAgfVxuXG4gICAgLnZhbHVlIHtcbiAgICAgICYuaW50ZXJhY3RpdmUge1xuICAgICAgICB0ZXh0LXRyYW5zZm9ybTogbG93ZXJjYXNlO1xuICAgICAgICBjb2xvcjogdmFyKC0tY29sb3ItcHJpbWFyeS1zaGFkZSk7XG4gICAgICB9XG4gICAgfVxuICB9XG59Il19 */`, "",{"version":3,"sources":["webpack://./src/app/components/car-data/car-data.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,eAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,QAAA;EACA,mBAAA;EACA,gBAAA;EACA,wBAAA;AACJ;AACI;EACE,YAAA;AACN;AAGM;EACE,yBAAA;EACA,iCAAA;AADR;AACA,g+BAAg+B","sourcesContent":[".content {\n  display: flex;\n  gap: 10px 5px;\n  flex-wrap: wrap;\n\n  .item {\n    display: flex;\n    flex-direction: column;\n    gap: 5px;\n    font-size: 0.938rem;\n    font-weight: 500;\n    width: calc(50% - 2.5px);\n\n    .name {\n      opacity: 0.6;\n    }\n\n    .value {\n      &.interactive {\n        text-transform: lowercase;\n        color: var(--color-primary-shade);\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
