// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.small {
  width: 50px;
  height: 50px;
}

.big {
  width: 100px;
  height: 100px;
}

.border {
  border: 2px solid rgba(0, 0, 0, 0.6);
}

.avatar-wrapper {
  border-radius: 10px;
  background-color: white;
  overflow: hidden;
}
.avatar-wrapper img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInVzZXItYXZhdGFyLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsV0FBQTtFQUNBLFlBQUE7QUFDRjs7QUFFQTtFQUNFLFlBQUE7RUFDQSxhQUFBO0FBQ0Y7O0FBRUE7RUFDRSxvQ0FBQTtBQUNGOztBQUVBO0VBQ0UsbUJBQUE7RUFDQSx1QkFBQTtFQUNBLGdCQUFBO0FBQ0Y7QUFDRTtFQUNFLFdBQUE7RUFDQSxZQUFBO0VBQ0EsbUJBQUE7QUFDSiIsImZpbGUiOiJ1c2VyLWF2YXRhci5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5zbWFsbCB7XG4gIHdpZHRoOiA1MHB4O1xuICBoZWlnaHQ6IDUwcHg7XG59XG5cbi5iaWcge1xuICB3aWR0aDogMTAwcHg7XG4gIGhlaWdodDogMTAwcHg7XG59XG5cbi5ib3JkZXIge1xuICBib3JkZXI6IDJweCBzb2xpZCByZ2IoMCAwIDAgLyA2MCUpO1xufVxuXG4uYXZhdGFyLXdyYXBwZXIge1xuICBib3JkZXItcmFkaXVzOiAxMHB4O1xuICBiYWNrZ3JvdW5kLWNvbG9yOiB3aGl0ZTtcbiAgb3ZlcmZsb3c6IGhpZGRlbjtcblxuICBpbWcge1xuICAgIHdpZHRoOiAxMDAlO1xuICAgIGhlaWdodDogMTAwJTtcbiAgICBib3JkZXItcmFkaXVzOiAxMHB4O1xuICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/components/user-avatar/user-avatar.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;AACF;;AAEA;EACE,oCAAA;AACF;;AAEA;EACE,mBAAA;EACA,uBAAA;EACA,gBAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;EACA,mBAAA;AACJ;AACA,43BAA43B","sourcesContent":[".small {\n  width: 50px;\n  height: 50px;\n}\n\n.big {\n  width: 100px;\n  height: 100px;\n}\n\n.border {\n  border: 2px solid rgb(0 0 0 / 60%);\n}\n\n.avatar-wrapper {\n  border-radius: 10px;\n  background-color: white;\n  overflow: hidden;\n\n  img {\n    width: 100%;\n    height: 100%;\n    border-radius: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
